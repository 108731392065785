.refwrapper {
  background-color: rgba(50, 50, 50, 0.07);
  width: 100%;
  min-height: 100vh;  
  padding-bottom: 64px; 
  position: relative;
}

.route-box {
  padding: 24px;
  margin: 24px;  
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.small {
  width: 90px;
  position: absolute;
  top: 350px;
  z-index: 1;
  right: 200px;
}
.medium {
  width: 136px;
  transform: rotate(47deg);
  position: absolute;
  top: 450px;
  z-index: 1;
  right: 250px;
}
.large {
  width: 550px;
  transform: rotate(-47deg);
  position: absolute;
  top: -50px;
  z-index: 1;
  right: 100px;
} 
.route-content-save-margin {
  margin-right: 20px;
}
@media (max-width: 500px) {
  .route-box {
    padding: 14px; 
  }
  .disc {
    display: none;
  }
  .responsive-br {
  	display: none;
  }
}