input[type='color'] {
  padding: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: -2px;
}
.stadium__markup-edit {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.BgColors {
  cursor: pointer;
  position: relative;
}
.color-picker-container {
  z-index: 1111;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid red;
  width: 10px;
  height: 10px;
}

.color-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 111;
}
