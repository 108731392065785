.referral-title {
  font-weight: 700;
  font-size: 18px;
}
.referral-condition {
  font-weight: 500;
}
.referral-details {
  margin-top: 15px;
}
.referral-details .referral-condition{
  margin-top: 25px;
}
.referral-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: space-between;
  margin-top: 115px;
}
.referral-input {
  font-size: 16px;
  margin: 0 5px 0 7px;
  width: 90px;
  height: 40px;
  border-radius: 5px;
	border: 2px solid #C4C4C4;
	padding: 8px;
}
.referral-input:hover {
  border-color: var(--fan-theme_clubPrimary);; 
  box-shadow: 0 0 5px rgba(var(--fan-theme_clubPrimary), 0.5);  
}

.referral-input:focus {
  border-color: var(--fan-theme_clubPrimary);  
  box-shadow: 0 0 7px rgba(var(--fan-theme_clubPrimary), 0.8);  
}