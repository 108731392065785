.referral-activate {
  font-weight: 500;
  position: relative;
  z-index: 999;
}
.referral-activate__title {
  font-size: 60px;
  font-weight: 900;
  line-height: 57px;
}
.referral-activate__title span {
  color: #1890FF;
}
.referral-activate__bonus__item span {
  color: #1890FF;
}
.referral-activate__description {
  margin-top: 20px;
}
.bonus__description {
  font-size: 13px;
  font-weight: 400;
  color: #8E8E8E;
}
.referral-activate__box {
  margin-top: 25px;
}
.referral-activate__bonus__item {
  display: flex;
  gap: 8px;
  align-items: center;
}
.referral-activate__bonus {
  margin-top: 10px;
}
.disc {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #8E8E8E;
}
.referral-activate-button {
  margin-top: 52px;
  border-radius: 10px;
  width: 214px;
  height: 60px;
  font-size: 20px;
  color: #fff;
}
.bonus__description-margin {
  margin-top: 10px;
}
:where(.css-dev-only-do-not-override-1xyf71q).ant-btn-primary {
  color: #fff;
  background-color: #1890FF;
  box-shadow: none;
}
.referral-activate-button {
  background-color: #1890FF;
  color: white;  
  border: none;  

  &:hover,
  &:focus {
    background-color: #3693eb; 
    border: none;
  }

  &:active {
    background-color: #2068ab;
    border: none;
  }

  transition: background-color 0.3s ease, color 0.3s ease;  
}
@media (max-width: 500px) {
  .referral-activate__title {
    font-size: 10.5vw;
    line-height: 47px;
  }
}